<template>
  <div class="rightTool">
    <div class="reminder">
    <div></div>
      <div class="explain" v-if="name!='查看计划'">
        请先在地图中点选圈出片区位置，<span
          style="color: #ff0e52; font-size: 18px"
          >双击结束</span
        >，可拖动圆点进行微调
      </div>
      <div>
      <!-- <el-button v-if="planDetailstater" class="tool" type="primary" @click="draw('polygon', 'draw')"
        >点击划线</el-button
      > -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bar",
  components: {},
  props: {},
  data() {
    return {
      legendData: {},
      zoom: 14,
      postionVal: 4.62,
      planDetailstater:true,
      name:'添加计划'
    };
  },
  computed: {},
  destroyed() {
    this.$eventBus.$off("planDetails");
  },
  mounted() {
    // 点击左侧复选（原来）
    this.$eventBus.$on("planDetails", (data) => {
      this.planDetailstater = data;
      this.name = data ? "编辑计划" : "查看计划"
    });
  },
  methods: {
    /**
     * 添加计划//跳转
     */
    goPath(path) {
      this.$router.push({ path: path });
    },
    draw(value, type) {
      this.$parent.pLoaction = true;
      this.$parent.closeDraw();
      if (type == "draw") {
        this.$emit("draw", value, true);
        this.toolActive = value;
        this.$parent.drawState = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .rightTool {
// position: absolute;
// bottom: 0;
// height: calc(100vh - 0.9rem);

// transition: right 0.5s;
// z-index: 2;
// }
.reminder {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  background: #ffffff;
  width: 100%;
  height: 60px;
  z-index: 1;
  .box {
    display: flex;
    flex-direction: row;
    // 面包屑
    .crumbs {
      height: 60px;
      line-height: 60px;
      span {
        color: #3069e1;
      }
    }
  }

  // 说明
  .explain {
    font-size: 18px;
    height: 60px;
    line-height: 60px;
    // margin-left: 30px;
  }
}
.tool {
  // position: absolute;
  // top: 0.16rem;
  // right: 0.11rem;
  height: 40px;
  margin: 10px;
  font-size: 0.15rem;
}
</style>
